var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{staticClass:"users-condition-view",attrs:{"slim":""}},[_c('a-spin',{attrs:{"spinning":_vm.usersInfoService.isLoading}},[_c('black-white-list-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'values',
          'prefix': 'users_condition_',
          'validation': 'required',
          'formatter': _vm.format
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }